import React, { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import Icon from "theme/components/atoms/Icon";
import Text from "theme/components/atoms/Form/Input/Text";
import { useAutocompleteInput } from "theme/components/organisms/Autocomplete/useAutocomplete";

const labels = defineMessages({
  search: {
    id: "modules.Search.SearchBar.SearchInput.placeholder",
    defaultMessage: "Search",
  },
});

const SearchInputMobile = ({
  name,
  value,
  id,
  onFocus,
  isExpanded,
  selected,
  onChange,
  isOpen,
  ...rest
}) => {
  const intl = useIntl();
  const autocompleteProps = useAutocompleteInput({ isExpanded, selected });

  const [isTextVisible, setTextVisible] = useState(false);

  const handleSearchClick = () => {
    setTextVisible(!isTextVisible);
  };

  const closeSearchBar = () => {
    onChange({ search: "" });
  };

  return (
    <div className="search-mobile">
      <Text
        {...rest}
        {...autocompleteProps}
        itemProp="query-input"
        name={name}
        value={value}
        id={id}
        className={isTextVisible ? "search-input" : "search-input-hidden"}
        placeholder={intl.formatMessage(labels.search)}
        onFocus={onFocus}
        aria-label={intl.formatMessage(labels.search)}
        appearance="searchbar"
      />

      <div
        className="search-mobile"
        onClick={handleSearchClick}
        style={{ cursor: "pointer" }}
      >
        {!isTextVisible ? (
          <Icon icon="search" color="black" size="mini" title="search" />
        ) : (
          <div onClick={closeSearchBar} className="icon">
            <Icon icon="cross" color="black" size="tiny" title="cross" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchInputMobile;
