import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import Text from "theme/components/atoms/Form/Input/Text";
import Form from "theme/components/atoms/Form/Form";
import { SubmitButton } from "theme/components/atoms/Button";
import SubscribeEmailToNewsletterMutation from "./SubscribeEmailToNewsletterMutation.gql";
import EnhanceSubscribeNewsletter from "./EnhanceSubscribeNewsletter";
import NewsletterMessages, {
  SuccessMessages,
  ErrorMessages,
} from "./NewsletterMessages";

const messages = defineMessages({
  placeholder: {
    id: "modules.Newsletter.Subscribe.placeholder",
    defaultMessage: "john.smith@example.com",
  },
  callToAction: {
    id: "modules.Newsletter.Subscribe.callToAction",
    defaultMessage: "Subscribe",
  },
  label: {
    id: "modules.Newsletter.Subscribe.label",
    defaultMessage: "Email",
  },
});

const Subscribe = ({
  intl,
  subscribeEmailToNewsletter,
  commandPending,
  statusMessage,
}) => (
  <div className="subscribe">
    <Form onValidSubmit={subscribeEmailToNewsletter}>
      <label htmlFor="email_newsletter">
        {intl.formatMessage(messages.label)}
      </label>
      <div className="subscribe__input">
        <Text
          id="email_newsletter"
          name="email"
          placeholder={intl.formatMessage(messages.placeholder)}
        />
        <SubmitButton
          appearance="primary"
          state={commandPending ? "pending" : undefined}
        >
          {intl.formatMessage(messages.callToAction)}
        </SubmitButton>
      </div>
    </Form>
    <NewsletterMessages statusMessage={statusMessage} />
  </div>
);

export default EnhanceSubscribeNewsletter({
  SubscribeEmailToNewsletterMutation,
  SuccessMessages,
  ErrorMessages,
})(injectIntl(Subscribe));
