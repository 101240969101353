import React from "react";
import { FormattedMessage } from "react-intl";

import Logo from "theme/components/atoms/Logo";
import IsDesktop from "theme/utils/isDesktop";
import EnhanceFooter from "./EnhanceFooter";
import BottomBar from "./BottomBar";
import SocialNewsletterBar from "./SocialNewsletterBar";
import UserQuery from "../Header/UserQuery.gql";

const Footer = (props) => {
  return (
    <IsDesktop>
      {(isDesktop) => (
        <React.Fragment>
          <footer className="footer">
            <SocialNewsletterBar />
            <div className="footer__wrapper">
              {isDesktop && <Logo />}
              <BottomBar
                contact={props.contact}
                user={props.me.me}
                firstColumn={props.firstColumn}
                secondColumn={props.secondColumn}
                thirdColumn={props.thirdColumn}
                fourthColumn={props.fourthColumn}
                isDesktop={isDesktop}
              />
              {!isDesktop && <Logo />}
            </div>

            <div className="footer__copyright">
              <FormattedMessage
                id="layouts.Footer.copyright"
                defaultMessage="© Collégien {date} - All rights reserved"
                values={{ date: new Date().getFullYear() }}
              />
            </div>
          </footer>
        </React.Fragment>
      )}
    </IsDesktop>
  );
};

const SmartFooter = EnhanceFooter({ UserQuery })(Footer);

export default SmartFooter;
