import React from "react";
import { FormattedMessage } from "react-intl";

import LabelledIcon from "theme/components/molecules/LabelledIcon";
import Icon from "theme/components/atoms/Icon";
import IsDesktop from "theme/utils/isDesktop";
import Link from "theme/components/atoms/Typography/Link";

import EnhanceConnectedAccount from "./EnhanceConnectedAccount";

const ConnectedAccount = (props) => (
  <IsDesktop>
    {(isDesktop) => (
      <Link to="/user">
        {isDesktop ? (
          <LabelledIcon appearance="vertical" icon="user" title="My account">
            <FormattedMessage
              id="modules.User.ConnectedAccount.signed"
              defaultMessage="Account"
            />
          </LabelledIcon>
        ) : (
          <Icon appearance="vertical" icon="user" title="My account" />
        )}
      </Link>
    )}
  </IsDesktop>
);

export default EnhanceConnectedAccount(ConnectedAccount);
