import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { usePreloadProps } from "theme/modules/Router/usePreload";

/* Currently we are using a link but we should not since the use case is too different */
const NavigationItem = (props) => {
  const preloadProps = usePreloadProps({
    to: props.to,
    onMouseOver: props.onMouseOver,
  });
  return (
    <Link
      className={classnames("navigation-menu__item", {
        "navigation-menu__item--active": props.isActive,
        "navigation-menu__item--push": props.isPush,
        "navigation-menu__item--disable-click": props.disableClick,
        [`navigation-menu__item--${props.extraClass}`]: props.extraClass,
      })}
      to={props.to}
      onClick={props.onClick}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      {...preloadProps}
    >
      <span>{props.children}</span>
    </Link>
  );
};

NavigationItem.propTypes = {
  appearance: PropTypes.oneOf(["mobile", "desktop"]).isRequired,
};

export default NavigationItem;
