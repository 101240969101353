import React, { useState, useEffect, useRef } from "react";
import { RoundIconButton } from "theme/components/atoms/Button";
import EnhanceContentSlider from "./EnhanceContentSlider";
import ResizedImage from "theme/components/atoms/ResizedImage";
import Link from "theme/components/atoms/Typography/Link";
import { makeAbsoluteUrl } from "theme/modules/Core";

const handleTranslate = (translateX, setTranslateX) => {
  const translateToRight = (gap = 0, allItems = 0) => {
    const newPosition = translateX - gap;

    if (allItems + newPosition < 0) {
      setTranslateX(-allItems);
    } else {
      setTranslateX(newPosition);
    }
  };

  const translateToLeft = (gap = 0) => {
    const newPosition = translateX + gap;
    if (newPosition > 0) {
      setTranslateX(0);
    } else {
      setTranslateX(newPosition);
    }
  };

  return [translateToRight, translateToLeft];
};
const ContentSlider = (props) => {
  const [width, setWidth] = useState(0);
  const ref = useRef(null);
  useEffect(() => {
    setWidth(ref.current.clientWidth);
  }, []);
  const [translateX, setTranslateX] = useState(0);

  if (props.loading) {
    return (
      <div className="content-slider content-slider--skeleton">
        <button className="button button--primary button--default button--disabled" />
        <div className="content-slider__full">
          <div className="content-slider__item">
            <div className="content-slider__left">
              <div className="image" />
            </div>
            <div className="content-slider__right">
              <div className="content-slider__content">
                <span className="content-slider__title skeleton--animate" />
                <span className="content-slider__subtitle skeleton--animate" />
                <p className="content-slider__text skeleton--animate" />
              </div>
            </div>
          </div>
        </div>
        <button className="button button--primary button--default" />
      </div>
    );
  }

  const contentCount = props.content.length - 1;

  const [translateToRight, translateToLeft] = handleTranslate(
    translateX,
    setTranslateX
  );

  return (
    <div className="content-slider">
      <RoundIconButton
        state={translateX >= 0 ? "disabled" : null}
        appearance="primary"
        onClick={() => translateToLeft(width)}
        onDisableClick={() => {}}
        icon="chevron-left"
        iconSize="tiny"
        title={""}
      />
      <div
        className="content-slider__full"
        style={{
          transform: `translate(${translateX}px)`,
          width: `${contentCount * 100}%`,
        }}
      >
        {props.content.map((content, item) => {
          return (
            <div
              className="content-slider__item"
              ref={ref}
              key={item}
              style={{ width: `${100 / contentCount}%` }}
            >
              <div className="content-slider__left">
                {content.link && content.link !== "/" ? (
                  <Link
                    buttonAppearance="ghost"
                    to={makeAbsoluteUrl(content.link)}
                  >
                    <ResizedImage
                      src={content.image}
                      srcset={content.srcset}
                      alt={content.title}
                      cover
                    />
                  </Link>
                ) : (
                  <ResizedImage
                    src={content.image}
                    srcset={content.srcset}
                    alt={content.title}
                    cover
                  />
                )}
              </div>
              <div className="content-slider__right">
                <div className="content-slider__content">
                  <h4 className="content-slider__title">{content.title}</h4>
                  <span className="content-slider__subtitle">
                    {content.subtitle}
                  </span>
                  <p className="content-slider__text">{content.content}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <RoundIconButton
        state={
          translateX <= -(width * (props.content.length - 2))
            ? "disabled"
            : null
        }
        appearance="primary"
        onClick={() => translateToRight(width, width * props.content.length)}
        icon="chevron-right"
        iconSize="tiny"
        title={"next"}
      />
    </div>
  );
};

export default EnhanceContentSlider()(ContentSlider);
