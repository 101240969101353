import React, { useEffect } from "react";
import classNames from "classnames";
import ProductsResults from "../ProductsResults";
import CategoriesResults from "../CategoriesResults";
import Stack from "theme/components/atoms/Layout/Stack";
import SearchBarResultsEmpty from "./SearchBarResultsEmpty";
import SearchBarResultsLoading from "./SearchBarResultsEmptyLoading";
import SearchBarResultsError from "./SearchBarResultsEmptyError";

const hasResults = ({ products, categories, brands }) =>
  products?.length > 0 ||
  categories?.filter(Boolean)?.length > 0 ||
  brands?.length > 0;

const SearchBarResults = (props) => {
  const onChange = props.onChange;
  useEffect(() => {
    const escapeKeyPressCloseOverlay = (e) => {
      const evt = e || window.event;
      let isEscape = false;
      let isEnter = false;
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc";
        isEnter = evt.key === "Enter";
      } else {
        isEscape = evt.keyCode === 27;
        isEnter = evt.key === 13;
      }
      if (isEscape) {
        onChange({ search: "" });
      }
      if (isEnter) {
        document
          .querySelector(".autocomplete-results__option--selected a")
          .click();
      }
    };
    const closeOverlay = () => onChange({ search: "" });
    document
      .querySelector(".header__search-overlay")
      .addEventListener("click", closeOverlay);

    document.addEventListener("keydown", escapeKeyPressCloseOverlay);
    return () => {
      document.removeEventListener("keydown", escapeKeyPressCloseOverlay);
      document
        .querySelector(".header__search-overlay")
        .removeEventListener("click", closeOverlay);
    };
  }, [onChange]);
  const isEmpty = !props.results || !hasResults(props.results);

  const closeSearchBar = () => {
    onChange({ search: "" });
  };

  if (props.loading) {
    return <SearchBarResultsLoading />;
  }
  if (props.error) {
    return <SearchBarResultsError />;
  }
  if (isEmpty) {
    return <SearchBarResultsEmpty />;
  }

  if (props.results.products?.length > 0) {
    props.results.products = props.results.products
      .filter(
        (product) =>
          product.type_id === "configurable" ||
          product.type_id === "virtual" ||
          product.type_id === "amgiftcard"
      )
      .slice(0, 8);
  }

  if (props.results.categories?.filter(Boolean)?.length > 0) {
    props.results.categories = props.results.categories.slice(0, 8);
  }

  return (
    <div
      className={classNames("searchbar-results", {
        "searchbar-results--two-columns":
          props.results.products?.length > 0 &&
          props.results.categories?.filter(Boolean)?.length > 0,
      })}
    >
      <Stack size="2">
        <div className="searchbar-results__results">
          {props.results.products?.length > 0 && (
            <div key="products" className="searchbar-product">
              <ProductsResults
                results={props.results.products}
                selected={props.selected}
                onSelect={props.onSelect}
                onChange={props.onChange}
                search={props.search}
              />
            </div>
          )}
          {props.results.categories?.filter(Boolean)?.length > 0 && (
            <div
              key="categories-pages"
              className={classNames("searchbar-categories", {
                "searchbar-categories--only":
                  props.results.products?.length === 0,
              })}
            >
              <Stack size="2" mobileSize="4">
                {props.results.categories?.filter(Boolean)?.length > 0 && (
                  <CategoriesResults
                    key="categories"
                    results={props.results.categories.filter(Boolean)}
                    selected={props.selected}
                    onSelect={props.onSelect}
                    onChange={props.onChange}
                  />
                )}
              </Stack>
            </div>
          )}

          <div className="close-search" onClick={closeSearchBar}>
            X
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default SearchBarResults;
