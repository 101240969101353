import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import TopBar from "./TopBar";
import ResponsiveNavigation from "./ResponsiveNavigation";
import { compose, withState, withHandlers } from "recompose";
import { withRouter } from "react-router";
import EnhanceHeader from "./EnhanceHeader";
import UserQuery from "./UserQuery.gql";
import CartQtyQuery from "./CartQtyQuery.gql";
import LogoutMutation from "./LogoutMutation.gql";
import ConfigurationQuery from "./ConfigurationQuery.gql";
import IsDesktop from "theme/utils/isDesktop";
import NewVisitorModal from "theme/components/organisms/NewVisitorModal";
import { trackUser } from "../../components/helpers/AnalyticsV4/TrackAnlyticsV4";

function resetAllDisplayCounts() {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith("newVisitorPopupDisplayCount_")) {
      localStorage.setItem(key, "0");
    }
  });
}

const Header = (props) => {
  useEffect(() => {
    resetAllDisplayCounts();
  }, []);

  return (
    <Fragment>
      <NewVisitorModal
        newVisitorPopup={props.storeConfiguration?.getNewVisitorPopup}
      />
      <div className="header">
        <TopBar
          toggleMenu={props.toggleMenu}
          showMenu={props.showMenu}
          setActiveMenu={props.setActiveMenu}
          toggleSearch={props.toggleSearch}
          activeSearch={props.activeSearch}
          user={props.me.me}
          userLoading={props.me.loading}
          cartLength={
            !props.cartLength.cart ? null : props.cartLength.cart.items_qty
          }
          logout={() => props.logout({ callback: null })}
          configuration={props.storeConfiguration.storeConfiguration}
        />
        <ResponsiveNavigation
          user={props.me.me}
          toggleMenu={props.toggleMenu}
          hideMenu={props.hideMenu}
          userLoading={props.me.loading}
          categories={props.categories}
          activeMenu={props.activeMenu}
          logout={() => props.logout({ callback: null })}
          cartLength={
            !props.cartLength.cart ? null : props.cartLength.cart.items_qty
          }
          setActiveMenu={props.setActiveMenu}
          configuration={props.storeConfiguration.storeConfiguration}
        />
      </div>
      <IsDesktop>
        {(isDesktop) => {
          if (!isDesktop) {
            return <div className="empty-header-wrapper" />;
          }
          return false;
        }}
      </IsDesktop>
    </Fragment>
  );
};

const SmartHeader = compose(
  withState("activeMenu", "setActiveMenu", false),
  withState("activeSearch", "setActiveSearch", false),
  withHandlers({
    showMenu: (props) => () => props.setActiveMenu(true),
    hideMenu: (props) => () => props.setActiveMenu(false),
    toggleMenu: (props) => () => {
      document.body.style.overflow = !props.activeMenu ? "hidden" : "initial";
      props.setActiveMenu(!props.activeMenu);
    },
    toggleSearch: (props) => () => props.setActiveSearch(!props.activeSearch),
  }),
  EnhanceHeader({
    UserQuery,
    ConfigurationQuery,
    CartQtyQuery,
    LogoutMutation,
    onLogout: (props) => {
      return ({ status, data }) => {
        if (status === "success") {
          // Temporary fix, waiting for more cache control on apollo
          window.location.href = "/";
        } else {
          // TODO add proper error handling
          console.error("Error", data);
        }
      };
    },
  }),
  trackUser(),
  withRouter
)(Header);

SmartHeader.propTypes = {
  categories: PropTypes.array,
  user: PropTypes.object,
};

export default SmartHeader;
