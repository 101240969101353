import { compose } from "recompose";
import { graphql } from "react-apollo";
import ConfigurationQuery from "./ConfigurationQuery.gql";
const EnhanceSocialNewsletterBar = () =>
  compose(
    graphql(ConfigurationQuery, {
      props: ({ data }) => {
        return {
          loading: data.loading,
          footerNewsletterDescription:
            data.storeConfiguration &&
            data.storeConfiguration.footerNewsletterDescription,
          socialFacebookLink:
            data.storeConfiguration &&
            data.storeConfiguration.socialFacebookLink,
          socialTwitterLink:
            data.storeConfiguration &&
            data.storeConfiguration.socialTwitterLink,
          socialInstagramLink:
            data.storeConfiguration &&
            data.storeConfiguration.socialInstagramLink,
        };
      },
    })
  );

export default EnhanceSocialNewsletterBar;
