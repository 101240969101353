import React, { createContext, useContext, useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import PropTypes from "prop-types";

const ROOT_SELECTOR_PLACEHOLDER = "__ROOT__";
const StyleContext = createContext();

/**
 * @returns string
 */
const generateRandomId = () => {
  return `wysiwyg-styles`;
};

/**
 * @type {React.FC}
 */
const StyleProvider = ({ children, ...props }) => {
  const wrapperId = useMemo(() => generateRandomId(), []);

  return (
    <StyleContext.Provider
      value={(style) =>
        style.replace(
          new RegExp(ROOT_SELECTOR_PLACEHOLDER, "g"),
          `#${wrapperId}`
        )
      }
    >
      <div {...props} id={wrapperId}>
        {children}
      </div>
    </StyleContext.Provider>
  );
};

/**
 * @type {React.FC<StyleProps>}
 */
const Style = ({ children, rootSelector }) => {
  const scopeStyle = useContext(StyleContext);

  const style = useMemo(() => {
    let rawStyles = "";
    if (typeof children === "string") {
      rawStyles = children;
    } else {
      rawStyles = ReactDOMServer.renderToStaticMarkup(children);
    }

    if (!rootSelector) {
      return rawStyles;
    }
    return rawStyles.replace(
      new RegExp(rootSelector, "g"),
      ROOT_SELECTOR_PLACEHOLDER
    );
  }, [children, rootSelector]);

  return <style dangerouslySetInnerHTML={{ __html: scopeStyle(style) }} />;
};

/**
 * @typedef StyleProps
 * @property {string} children
 * @property {string} [rootSelector=""]
 */
Style.propTypes = {
  children: PropTypes.node,
  rootSelector: PropTypes.string,
};

export { StyleProvider };
export default Style;
