import { useCallback, useRef, useState } from "react";
import useDebouncedCallback from "theme/components/helpers/useDebouncedCallback";
import useAutocomplete from "theme/components/organisms/Autocomplete/useAutocomplete";
import useSearchBarNavigation from "./useSearchBarNavigation";

const useSearchBar = ({ search, setSearch, data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const contentRef = useRef();
  const [selected, onSelect] = useAutocomplete(
    contentRef,
    isOpen,
    data?.searchSuggestions?.query,
    "all",
    useCallback(() => {
      setSearch({ search: "" });
      setIsOpen(false);
    }, [setSearch])
  );

  const { searchUrl, searchTarget, onSubmit } = useSearchBarNavigation(search);

  const setSearchDebounced = useDebouncedCallback(setSearch, 1000);
  const onChange = (data) => {
    setSearchDebounced(data);
    const keepOpen = data?.search?.length > 0;
    setIsOpen(keepOpen);

    if (keepOpen) {
      document.getElementsByTagName("body")[0].classList.add("search--opened");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("search--opened");
    }
  };
  const onFocus = () => {
    if (search?.search?.length > 0) {
      setIsOpen(true);
    }
  };

  const showAutocompleteResults = data && !data.loading;

  return {
    contentRef,
    searchUrl,
    searchTarget,
    onSubmit,
    onChange,
    onFocus,
    showAutocompleteResults,
    isOpen,
    setIsOpen,
    selected,
    onSelect,
  };
};

export default useSearchBar;
