import React from "react";
import EnhanceOtherLookbook from "./EnhanceOtherLookbook";
import ResizedImage from "theme/components/atoms/ResizedImage";
import Link from "theme/components/atoms/Typography/Link";
import IsDesktop from "theme/utils/isDesktop";
import { makeAbsoluteUrl } from "theme/modules/Core";

const OtherLookbook = ({ title, categories, loading }) => {
  if (loading) {
    return (
      <div className="other-lookbook other-lookbook--skeleton">
        <span className="other-lookbook__title" />
        <div className="other-lookbook__list">
          <div className="other-lookbook__item" />
          <div className="other-lookbook__item" />
          <div className="other-lookbook__item" />
          <div className="other-lookbook__item" />
          <div className="other-lookbook__item" />
          <div className="other-lookbook__item" />
        </div>
      </div>
    );
  }
  if (!categories || !categories.length) {
    return false;
  }
  return (
    <div className="other-lookbook">
      <span className="other-lookbook__title">{title}</span>
      <div className="other-lookbook__list">
        {categories.map((category) => {
          if (!category.lookbook_media_widget) {
            return false;
          }
          return (
            <IsDesktop key={category.id}>
              {(isDesktop) => (
                <div className="other-lookbook__item">
                  <Link
                    buttonAppearance="ghost"
                    to={makeAbsoluteUrl(category.path)}
                  >
                    <ResizedImage
                      src={category.lookbook_media_widget.url}
                      alt={category.lookbook_media_widget.title}
                      srcset={category.lookbook_media_widget.srcset}
                      format={
                        isDesktop ? "otherLookbook" : "otherLookbookMobile"
                      }
                    />
                  </Link>
                </div>
              )}
            </IsDesktop>
          );
        })}
      </div>
    </div>
  );
};

export default EnhanceOtherLookbook()(OtherLookbook);
