import React from "react";
import SearchInput from "./SearchInput";
import OneLineForm from "theme/components/organisms/Form/OneLineForm";

const SearchBarForm = ({
  search,
  searchTarget,
  onChange,
  onFocus,
  isExpanded,
  selected,
  isOpen,
}) => {
  return (
    <OneLineForm
      appearance="button-icon"
      itemProp="potentialAction"
      itemScope
      itemType="http://schema.org/SearchAction"
      onChange={onChange}
      input={
        <>
          <meta itemProp="target" content={searchTarget} />

          <SearchInput
            name="search"
            id="search"
            value={search?.search}
            onFocus={onFocus}
            isExpanded={isExpanded}
            selected={selected}
            onChange={onChange}
            isOpen={isOpen}
          />
        </>
      }
    />
  );
};

export default SearchBarForm;
