import React from "react";
import classnames from "classnames";
import { compose, withProps } from "recompose";
import { Helmet } from "react-helmet-async";
import { injectIntl } from "react-intl";

import config from "config/website";
import useFullUrl from "web/core/shop/useFullUrl";
import ErrorBoundary from "theme/components/helpers/ErrorBoundary";
import PageError from "theme/modules/PageError";
import { LayoutThemeContext } from "./LayoutContext";

const GenericLayout = ({
  header,
  footer,
  children,
  intl,
  refreshing = false,
}) => {
  const { theme } = React.useContext(LayoutThemeContext);

  return (
    <div
      className={classnames("wrapper", {
        "wrapper--refreshing": refreshing,
      })}
      itemScope
      itemType="http://schema.org/WebSite"
    >
      <meta itemProp="url" content={useFullUrl("")} />
      <Helmet defaultTitle={config.defaultTitle}>
        <html lang={intl.locale} />
        <meta name="language" content={intl.locale} />
        <meta name="robots" content="Index,Follow" />
        <meta name="description" content={config.defaultDescription} />
      </Helmet>
      {header}
      <div className={classnames("container", `container--${theme}`)}>
        <div className="container__content">
          <ErrorBoundary>
            {({ hasError }) => (hasError ? <PageError /> : children)}
          </ErrorBoundary>
        </div>
      </div>
      {footer}
    </div>
  );
};

export default compose(
  injectIntl,
  withProps(({ location }) => {
    return {
      refreshing: location && location.state && location.state.refreshing,
    };
  })
)(GenericLayout);
