import React from "react";
import { compose } from "recompose";
import { injectIntl, defineMessages } from "react-intl";
import Spinner from "theme/components/atoms/Spinner";
import Link from "theme/components/atoms/Typography/Link";
import Price from "theme/components/atoms/Typography/Price";
import EnhanceMiniCartContent from "./EnhanceMiniCartContent";
import CartQuery from "theme/pages/Cart/CartQuery.gql";
import MiniCartItem from "theme/modules/Cart/CartItem/MiniCartItem";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";

const messages = defineMessages({
  miniCartTitle: {
    id: "modules.Cart.MiniCart.MiniCartContent.title",
    defaultMessage: "My cart",
  },
  productsCount: {
    id: "modules.Cart.MiniCart.MiniCartContent.productsCount",
    defaultMessage: "({qty} {qty, plural, one {product} other {products}})",
  },
  miniCartEmpty: {
    id: "modules.Cart.MiniCart.MiniCartContent.empty",
    defaultMessage: "You don't have any product in your cart yet.",
  },
  miniCartTotalMain: {
    id: "modules.Cart.MiniCart.MiniCartContent.totalMain",
    defaultMessage: "Total",
  },
  miniCartTotalDiscount: {
    id: "modules.Cart.MiniCart.MiniCartContent.discount",
    defaultMessage: "Discount",
  },
  miniCartTotalSub: {
    id: "modules.Cart.MiniCart.MiniCartContent.totalSub",
    defaultMessage: "Excluding shipping fees",
  },
  seeCartAction: {
    id: "modules.Cart.MiniCart.MiniCartContent.seeCart",
    defaultMessage: "View my cart",
  },
  checkoutAction: {
    id: "modules.Cart.MiniCart.MiniCartContent.checkout",
    defaultMessage: "Proceed to checkout",
  },
  viewMore: {
    id: "modules.Cart.MiniCart.MiniCartContent.viewMore",
    defaultMessage: "See my {qty} products",
  },
});

const MiniCartContent = ({ loading, cart, intl, setDisplayMiniCart }) => {
  if (loading) {
    return (
      <div className="mini-cart-content">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="mini-cart-content">
      <div className="mini-cart-content__title">
        <span className="mini-cart-content__title__main">
          {intl.formatMessage(messages.miniCartTitle)}
        </span>
        {cart.items_qty > 0 && (
          <span>
            {intl.formatMessage(messages.productsCount, {
              qty: cart.items_qty,
            })}
          </span>
        )}
      </div>
      {cart.items.length > 0 ? (
        <div className="mini-cart-items">
          {cart.items.map((item) => (
            <MiniCartItem key={item.item_id} item={item} />
          ))}
        </div>
      ) : (
        <div className="mini-cart-content__empty">
          {intl.formatMessage(messages.miniCartEmpty)}
        </div>
      )}
      {cart.items.length > 0 && (
        <div className="mini-cart-content__total">
          <div className="mini-cart-content__total__main">
            <span className="mini-cart-content__total__main__label">
              {intl.formatMessage(messages.miniCartTotalMain)}{" "}
            </span>
            <Price price={cart.totals.totalInclTax} />
          </div>
          {cart.totals.discount.value.amount !== 0 && (
            <div className="mini-cart-content__total__discount">
              <span className="mini-cart-content__total__main__label">
                {intl.formatMessage(messages.miniCartTotalDiscount)}{" "}
              </span>
              <Price price={cart.totals.discount} />
            </div>
          )}
        </div>
      )}
      {cart.items.length > 0 && (
        <Link
          buttonAppearance="primary"
          to="/cart"
          onClick={() => setDisplayMiniCart(false)}
        >
          {intl.formatMessage(messages.seeCartAction)}
        </Link>
      )}
    </div>
  );
};

export default compose(
  withHideOnErrorBoundary,
  injectIntl,
  EnhanceMiniCartContent({ CartQuery })
)(MiniCartContent);
