import branchServerClient from "web/core/branchServerClient";
import { compose, withProps } from "recompose";
import { graphql } from "react-apollo";
import { withRouter } from "react-router";
import makeCommandDispatcherWithMessageFeedback from "web/core/apollo/makeCommandDispatcherWithMessageFeedback";

const EnhanceHeader = ({
  UserQuery,
  ConfigurationQuery,
  CartQtyQuery,
  LogoutMutation,
  onLogout,
}) =>
  compose(
    graphql(UserQuery, {
      name: "me",
    }),
    graphql(ConfigurationQuery, {
      name: "storeConfiguration",
    }),
    branchServerClient(
      withProps(() => ({
        cartLength: {},
      })),
      graphql(CartQtyQuery, {
        name: "cartLength",
      })
    ),
    withRouter,
    makeCommandDispatcherWithMessageFeedback({
      logout: (props) => () => ({
        options: {
          mutation: LogoutMutation,
          refetchQueries: ["User", "Cart", "CartQty"],
        },
        callback: onLogout(props),
      }),
    })
  );

export default EnhanceHeader;
