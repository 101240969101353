import { compose, withProps } from "recompose";
import { graphql } from "react-apollo";

import withCmsBlock from "theme/modules/CmsBlock/withCmsBlock";

const EnhanceFooter = ({ UserQuery }) =>
  compose(
    graphql(UserQuery, {
      name: "me",
    }),
    withCmsBlock({ name: "firstColumn", slug: "footer_1" }),
    withCmsBlock({ name: "secondColumn", slug: "footer_2" }),
    withCmsBlock({ name: "thirdColumn", slug: "footer_3" }),
    withCmsBlock({ name: "fourthColumn", slug: "footer_4" }),
    withProps((props) => ({
      loading:
        props.firstColumn.loading ||
        props.secondColumn.loading ||
        props.thirdColumn.loading ||
        props.fourthColumn.loading ||
        props.me.loading,
      firstColumn: props.firstColumn.data
        ? props.firstColumn.data.content
        : null,
      secondColumn: props.secondColumn.data
        ? props.secondColumn.data.content
        : null,
      thirdColumn: props.thirdColumn.data
        ? props.thirdColumn.data.content
        : null,
      fourthColumn: props.fourthColumn.data
        ? props.fourthColumn.data.content
        : null,
    }))
  );

export default EnhanceFooter;
