import React from "react";
import { FormattedMessage } from "react-intl";
import ResultsBlock from "theme/components/molecules/ResultsBlock";
import AutocompleteResults, {
  AutocompleteOption,
} from "theme/components/organisms/Autocomplete/AutocompleteResults";
import CategoriesResultItem from "./CategoriesResultItem";

const CategoriesResults = (props) => {
  return (
    <ResultsBlock
      title={
        <FormattedMessage
          id="modules.Search.SearchBar.CategoriesResults.title"
          defaultMessage="Categories"
        />
      }
    >
      <AutocompleteResults>
        {props.results
          .filter((elem) => elem?.id)
          ?.map((result) => (
            <AutocompleteOption
              key={result.id}
              id={`autocomplete-category-${result.id}`}
              path={result.path}
              selected={props.selected}
              onSelect={props.onSelect}
            >
              <div className="autocomplete__category">
                <CategoriesResultItem
                  result={result}
                  onChange={props.onChange}
                />
              </div>
            </AutocompleteOption>
          ))}
      </AutocompleteResults>
    </ResultsBlock>
  );
};

export default CategoriesResults;
