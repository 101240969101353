import React from "react";
import { FormattedMessage } from "react-intl";
import ConnectedAccount from "theme/modules/User/ConnectedAccount";
import LabelledIcon from "theme/components/molecules/LabelledIcon";
import Icon from "theme/components/atoms/Icon";
import Link from "theme/components/atoms/Typography/Link";
import Breakpoints from "theme/components/atoms/Breakpoints";
import EnhanceLoadable from "theme/components/helpers/Loadable/EnhanceLoadable";
import MediaQuery from "theme/components/helpers/MediaQuery";

const AccountLinkLoading = (props) => (
  <Link to="/">
    <MediaQuery minWidth={Breakpoints.menuBreakpoint}>
      {(matches) => {
        return matches ? (
          <LabelledIcon icon="redo" title="Loading, please wait..." size="mini">
            <FormattedMessage
              id="modules.User.AccountLink.loading"
              defaultMessage="Loading..."
            />
          </LabelledIcon>
        ) : (
          <Icon icon="redo" title="Loading, please wait..." size="mini" />
        );
      }}
    </MediaQuery>
  </Link>
);

const AccountLink = (props) =>
  props.user && props.user.id ? (
    <ConnectedAccount
      user={props.user}
      logout={props.logout}
      toggleMenu={props.toggleMenu}
    />
  ) : (
    <MediaQuery minWidth={Breakpoints.menuBreakpoint}>
      {(matches) => (
        <Link
          to={{
            pathname: "/login",
            state: { modal: false },
          }}
          onClick={props.toggleMenu || null}
        >
          {matches ? (
            <LabelledIcon appearance="vertical" icon="user" title="Sign in">
              <FormattedMessage
                id="modules.User.AccountLink.signin"
                defaultMessage="Sign in"
              />
            </LabelledIcon>
          ) : (
            <Icon
              appearance="vertical"
              icon="user"
              title="Sign in"
              size="mini"
            />
          )}
        </Link>
      )}
    </MediaQuery>
  );

export default EnhanceLoadable(
  (props) => !props.loading,
  AccountLinkLoading
)(AccountLink);
