import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import LabelledIcon from "theme/components/molecules/LabelledIcon";
import IsDesktop from "theme/utils/isDesktop";
import EnhanceReassurance from "./EnhanceReassurance";
import ReassuranceConfigurationQuery from "./ReassuranceConfigurationQuery.gql";

const Reassurance = ({
  loading,
  widgetReassurancePaymentWording,
  widgetReassuranceShippingWording,
  widgetReassuranceReturnWording,
  big = false,
}) => {
  if (loading) {
    return null;
  }

  const className = classnames("reassurance", {
    "reassurance--big": big,
  });

  return (
    <IsDesktop>
      {(isDesktop) => (
        <div className={className}>
          <div className="reassurance__item">
            <LabelledIcon
              icon="locker"
              size={isDesktop || big ? "huge" : "small"}
              appearance={isDesktop || big ? "vertical" : "default"}
            >
              <small>{widgetReassurancePaymentWording}</small>
            </LabelledIcon>
          </div>
          <div className="reassurance__item">
            <LabelledIcon
              icon="delivery"
              size={isDesktop || big ? "huge" : "small"}
              appearance={isDesktop || big ? "vertical" : "default"}
            >
              <small>{widgetReassuranceShippingWording}</small>
            </LabelledIcon>
          </div>
          <div className="reassurance__item">
            <LabelledIcon
              icon="refresh"
              size={isDesktop || big ? "huge" : "small"}
              appearance={isDesktop || big ? "vertical" : "default"}
            >
              <small>{widgetReassuranceReturnWording}</small>
            </LabelledIcon>
          </div>
        </div>
      )}
    </IsDesktop>
  );
};

Reassurance.propTypes = {
  widgetReassurancePaymentWording: PropTypes.string,
  widgetReassuranceShippingWording: PropTypes.string,
  widgetReassuranceReturnWording: PropTypes.string,
};

export default EnhanceReassurance({ ReassuranceConfigurationQuery })(
  Reassurance
);
