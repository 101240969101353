import React from "react";
import Link from "theme/components/atoms/Typography/Link";
import Image from "theme/components/atoms/Image";
import AutocompleteItem from "theme/components/organisms/Autocomplete/AutocompleteItem/AutocompleteItem";

const ProductItem = ({ result, onChange }) => {
  let name = "";
  let addon = "";

  if (result.name) {
    let parts = result.name.split("-");
    name = parts[0];
    addon = parts[1] || "";
  }
  return (
    <Link
      onClick={() => {
        onChange({ search: "" });
      }}
      key={result.sku}
      to={result.path}
      clickType="block"
    >
      <AutocompleteItem
        image={
          <Image
            src={result.imageUrl}
            alt={result.name}
            format="square"
            appearance="full"
          />
        }
        name={name}
        addon={addon}
      />
    </Link>
  );
};

export default ProductItem;
