import React from "react";
import { makeAbsoluteUrl } from "theme/modules/Core";
import Link from "theme/components/atoms/Typography/Link";
import ResizedImage from "theme/components/atoms/ResizedImage";
import Icon from "theme/components/atoms/Icon";
import { injectIntl } from "react-intl";

const Thumbnail = ({ intl, newsItem }) => {
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return (
    <div className="news-thumbnail">
      <div className="news-thumbnail__image">
        {newsItem.image && (
          <ResizedImage
            src={newsItem.image.media}
            alt={newsItem.image.alt}
            srcset={newsItem.image.srcset}
            format="newsThumbnailBig"
            cover
          />
        )}
      </div>
      <span className="news-thumbnail__date">
        {new Date(newsItem.date).toLocaleDateString(intl.locale, dateOptions)}
      </span>
      <span className="news-thumbnail__title">{newsItem.title}</span>
      <p className="news-thumbnail__short-description">{newsItem.content}</p>
      {newsItem.cta_link && (
        <Link
          buttonAppearance="ghost"
          to={newsItem.cta_link ? makeAbsoluteUrl(newsItem.cta_link.url) : "/"}
        >
          {newsItem.cta_label}
          <Icon icon="chevron-right" size="mini" title="link" />
        </Link>
      )}
    </div>
  );
};

export default injectIntl(Thumbnail);
