import React, { useState } from "react";
import { compose, branch } from "recompose";
import IsDesktop from "theme/utils/isDesktop";
import Form from "theme/components/atoms/Form/Form";
import Select from "theme/components/atoms/Form/Input/Select";
import Icon from "theme/components/atoms/Icon";
import AvailableStoreQuery from "./AvailableStoreQuery.gql";
import StoreViewUrlQuery from "./StoreViewUrlQuery.gql";
import EnhanceStoreViewSelector from "./EnhanceStoreViewSelector";

const storeLabels = {
  b2b_fr: "fr",
  b2c_fr: "fr",
  b2b_de: "de",
  b2c_de: "de",
  b2b_en: "en",
  b2c_en: "en",
  b2b_it: "it",
  b2c_it: "it",
};

const StoreViewSelector = (props) => {
  const [modal, toggleModal] = useState(false);
  return (
    <IsDesktop>
      {(isDesktop) =>
        isDesktop ? (
          <Form
            onChange={({ storeview }) => {
              props.setStoreView(storeview);
            }}
          >
            <div className="store-view-selector">
              <Select
                disableValidation
                light
                icon={
                  <Icon icon="chevron-down" size="tiny" title="select store" />
                }
                iconOpen={
                  <Icon icon="chevron-up" size="tiny" title="select store" />
                }
                name="storeview"
                id="storeview"
                value={props.data.shop.id}
                options={props.data.availableStores.map((storeview) => ({
                  value: storeview,
                  label: (
                    <>
                      <Icon
                        icon={`country_${storeLabels[storeview]}`}
                        size="small"
                        title="select store"
                      />
                      {storeLabels[storeview]}
                    </>
                  ),
                }))}
              />
            </div>
          </Form>
        ) : (
          <>
            <div
              className="store-view-mobile"
              onClick={() => toggleModal(true)}
            >
              <Icon
                icon={`country_${storeLabels[props.data.shop.id]}`}
                size="small"
                title="select store"
              />
              {storeLabels[props.data.shop.id]}
            </div>
            {modal && (
              <>
                <div className="store-view-overlay"></div>
                <div className="store-view-absolute">
                  <ul className="similar-color__modal__list">
                    {props.data.availableStores.map((storeview) => (
                      <div
                        onClick={() => {
                          props.setStoreView(storeview);
                        }}
                      >
                        <Icon
                          onClick={() => props.setStoreView(storeview)}
                          icon={`country_${storeLabels[storeview]}`}
                          size="huge"
                          title="select store"
                        />
                      </div>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </>
        )
      }
    </IsDesktop>
  );
};

export default compose(
  EnhanceStoreViewSelector({ AvailableStoreQuery, StoreViewUrlQuery }),
  branch(
    (props) => props.data.loading || props.data.availableStores.length < 2,
    () => () => null,
    (BaseComponent) => BaseComponent
  )
)(StoreViewSelector);
