import React from "react";
import Thumbnail from "./Thumbnail";
import EnhanceNews from "./EnhanceNews";

const News = ({ news, loading, title }) => {
  if (loading) {
    return (
      <div className="news-list news-list--loading-skeleton">
        <Thumbnail newsItem={{}} />
        <Thumbnail newsItem={{}} />
        <Thumbnail newsItem={{}} />
        <Thumbnail newsItem={{}} />
      </div>
    );
  }
  return (
    <div className="news-list">
      {title && <span className="news-list__title">{title}</span>}
      <div className="news-list__list">
        {news.map((item, index) => (
          <Thumbnail newsItem={item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default EnhanceNews()(News);
