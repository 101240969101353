import { compose, withProps, branch } from "recompose";
import { graphql } from "react-apollo";
import NewsQuery from "./NewsQuery.gql";
import LastNewsQuery from "./LastNewsQuery.gql";

import { fieldForIdInList } from "theme/utils/extractFromListHelper";
import { withTranslations } from "theme/modules/Core";
export default () =>
  compose(
    branch(
      (props) => props.mode === "manual",
      (BaseComponent) =>
        graphql(NewsQuery, {
          options: (props) => {
            return {
              variables: {
                ids: props.news_list.split(","),
              },
            };
          },
          props: ({ data }) => {
            return {
              loading: data.loading,
              news: !data.loading && data.news,
            };
          },
        })(BaseComponent),
      (BaseComponent) =>
        graphql(LastNewsQuery, {
          props: ({ data }) => {
            return {
              loading: data.loading,
              news: !data.loading && data.lastNews,
            };
          },
        })(BaseComponent)
    ),

    withTranslations((props) => [props.title]),
    withProps((props) => {
      const loading = Boolean(props.translationsLoading);
      if (loading) {
        return {
          loading,
        };
      }
      const extractTranslations = fieldForIdInList(props.translations);
      return {
        loading,
        title: extractTranslations(props.title, "value", ""),
      };
    })
  );
