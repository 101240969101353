import React from "react";
import classnames from "classnames";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";
import { compose, withState } from "recompose";
import Link from "theme/components/atoms/Typography/Link";
import LabelledIcon from "theme/components/molecules/LabelledIcon";
import Icon from "theme/components/atoms/Icon";
import Breakpoints from "theme/components/atoms/Breakpoints";
import MediaQuery from "theme/components/helpers/MediaQuery";
import MiniCartContent from "./MiniCartContent";

const messages = defineMessages({
  cart: {
    id: "modules.Cart.MiniCart.cart",
    defaultMessage: "Cart",
  },
});

const MiniCart = ({
  setDisplayMiniCart,
  intl,
  displayMiniCart,
  cartLength,
  setTimeoutOnMouseOver,
  timeoutOnMouseOver,
}) => {
  let cartItemQty = 0;
  if (cartLength && cartLength.cart && cartLength.cart.items_qty) {
    cartItemQty = cartLength.cart.items_qty;
  } else if (typeof cartLength === "number") {
    cartItemQty = cartLength;
  }
  return (
    <MediaQuery minWidth={Breakpoints.menuBreakpoint}>
      {(matches) => (
        <div
          className={classnames("mini-cart", {
            "mini-cart--active": displayMiniCart,
          })}
          onMouseEnter={() => {
            clearTimeout(timeoutOnMouseOver);
            setDisplayMiniCart(true);
          }}
          onMouseLeave={() => {
            clearTimeout(timeoutOnMouseOver);
            setTimeoutOnMouseOver(
              setTimeout(() => {
                setDisplayMiniCart(false);
              }, 1000)
            );
          }}
        >
          <Link
            to="/cart"
            onClick={() => {
              setDisplayMiniCart(false);
            }}
          >
            {matches ? (
              <LabelledIcon
                appearance="vertical"
                icon={cartItemQty > 0 ? "cart_full" : "cart"}
                title={intl.formatMessage(messages.cart)}
                size="mini"
              >
                <FormattedMessage
                  id="modules.Cart.MiniCart.cart"
                  defaultMessage="Cart"
                />
              </LabelledIcon>
            ) : (
              <Icon
                appearance="vertical"
                icon={cartItemQty > 0 ? "cart_full" : "cart"}
                title={intl.formatMessage(messages.cart)}
                size="mini"
              />
            )}
          </Link>
          {matches && displayMiniCart && (
            <div
              className="mini-cart__container"
              onMouseEnter={() => {
                clearTimeout(timeoutOnMouseOver);
              }}
              onMouseLeave={() => {
                clearTimeout(timeoutOnMouseOver);
                setDisplayMiniCart(false);
              }}
            >
              <MiniCartContent setDisplayMiniCart={setDisplayMiniCart} />
            </div>
          )}
        </div>
      )}
    </MediaQuery>
  );
};

export default compose(
  injectIntl,
  withState("displayMiniCart", "setDisplayMiniCart", false),
  withState("timeoutOnMouseOver", "setTimeoutOnMouseOver", false)
)(MiniCart);
