import { compose, branch, withProps } from "recompose";
import { graphql } from "react-apollo";
import OtherLookbookQuery from "./OtherLookbookQuery.gql";
export default () =>
  compose(
    branch(
      (props) => !props.lookbooks,
      () => () => null,
      (BaseComponent) => BaseComponent
    ),
    withProps((props) => {
      let lookbooks = props.lookbooks.split(",");
      lookbooks = lookbooks.map((lookbook) => parseInt(lookbook));
      return {
        lookbooks,
      };
    }),
    graphql(OtherLookbookQuery, {
      options: (props) => {
        return {
          variables: {
            categoryIds: props.lookbooks,
            translationIds: [props.title],
          },
        };
      },
      props: ({ data }) => {
        return {
          loading: data.loading,
          categories: !data.loading && data.categories,
          title:
            !data.loading &&
            data.translations &&
            data.translations[0] &&
            data.translations[0].value,
        };
      },
    })
  );
