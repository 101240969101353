import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Breakpoints from "theme/components/atoms/Breakpoints";
import MediaQuery from "theme/components/helpers/MediaQuery";
import MenuNavigation from "./MenuNavigation";

const ResponsiveNavigation = (props) => (
  <MediaQuery minWidth={Breakpoints.menuBreakpoint}>
    {(matches) => (
      <div
        className={classNames("menu", {
          "menu--desktop": matches,
          "menu--active": props.activeMenu,
        })}
      >
        {matches ? (
          <MenuNavigation.Desktop
            user={props.user}
            userLoading={props.userLoading}
            logout={props.logout}
            pushCategorieIds={
              props.configuration && props.configuration.menuPushCategoryId
                ? [props.configuration.menuPushCategoryId]
                : []
            }
            configuration={props.configuration}
            cartLength={props.cartLength}
            onLeaveMenu={props.onLeaveMenu}
          />
        ) : (
          <MenuNavigation.Mobile
            activeMenu={props.activeMenu}
            categories={props.categories}
            hideMenu={props.hideMenu}
            toggleMenu={props.toggleMenu}
            user={props.user}
            userLoading={props.userLoading}
            logout={props.logout}
            configuration={props.configuration}
            cartLength={props.cartLength}
            onLeaveMenu={props.onLeaveMenu}
          />
        )}
      </div>
    )}
  </MediaQuery>
);

ResponsiveNavigation.propTypes = {
  categories: PropTypes.array,
};

export default ResponsiveNavigation;
