import React from "react";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { Link } from "react-router-dom";
import AccountLink from "theme/modules/User/AccountLink";
import MiniCart from "theme/modules/Cart/MiniCart";
import Logo from "theme/components/atoms/Logo";
import NavigationItem from "./NavigationItem";
import SmartMenuNavigation from "./SmartMenuNavigation";
import WysiwygV2 from "theme/modules/WysiwygV2";
import { Transition } from "react-transition-group";
import classNames from "classnames";
import GenderNavigation from "theme/layouts/Header/MenuNavigation/GenderNavigation";
import { FormattedMessage } from "react-intl";
import withGenderFilter from "theme/components/helpers/withGenderFilter";
import SearchBar from "theme/modules/Search/SearchBar";

const defaultStyle = {};
const transitionStyles = {
  entering: { maxHeight: "100vh", opacity: "1" },
  entered: { maxHeight: "100vh", opacity: "1" },
  exiting: { maxHeight: "0", opacity: "0" },
  exited: { maxHeight: "0", opacity: "0" },
};
export const DumbMenuNavigation = (props) => {
  let menuGrid1 = [];
  let menuGrid2 = [];
  let menuGrid3 = [];
  let menuGrid1TitleSet = false;
  let menuGrid2TitleSet = false;
  let menuGrid3TitleSet = false;
  let displayShowAll = true;
  let genderParam = null;
  const currentGender = props.currentGender;
  if (props.subCats && props.subCats.length > 0) {
    props.subCats.map((category, index) => {
      if (category.hideForCurrentGender) {
        return false;
      }
      if (!currentGender || category.isNoGenderCategory === true) {
        genderParam = null;
      } else {
        genderParam = category.genderParam;
      }
      const path = category.path || "/";
      if (!category.menu_grid_position || category.menu_grid_position === "1") {
        if (!menuGrid1TitleSet && props.currentCat.menu_1_grid_title) {
          menuGrid1.push(
            <span
              className="navigation-menu__item-section-title"
              key={`${index}_grid1_1`}
            >
              {props.currentCat.menu_1_grid_title}
            </span>
          );
          menuGrid1TitleSet = true;
        }
        if (category.menu_pre_title) {
          menuGrid1.push(
            <span
              className="navigation-menu__item-section-title"
              key={`${index}_grid1_2`}
            >
              {category.menu_pre_title}
            </span>
          );
        }
        if (displayShowAll) {
          menuGrid1.push(
            <div
              className="navigation-menu__item--mobile navigation-menu__item--mobile--tab-left"
              key={`${index}_grid1_3`}
            >
              <NavigationItem
                appearance="mobile"
                to={{
                  pathname: props.currentCat.path,
                  search: !currentGender ? null : props.currentCat.genderParam,
                }}
                onClick={(e) => {
                  props.selectCategory(false);
                  return props.onLeaveMenu();
                }}
              >
                <FormattedMessage
                  id="layouts.Header.MenuNavigation.seeAll"
                  defaultMessage="Show all"
                />
              </NavigationItem>
            </div>
          );
          displayShowAll = false;
        }
        menuGrid1.push(
          <NavigationItem
            key={`${index}_grid1`}
            appearance="desktop"
            to={{
              pathname: path,
              search: genderParam,
            }}
            onClick={() => {
              props.selectCategory(false);
              return props.onLeaveMenu();
            }}
          >
            {category.name}
          </NavigationItem>
        );
      } else if (category.menu_grid_position === "2") {
        if (!menuGrid2TitleSet && props.currentCat.menu_2_grid_title) {
          menuGrid2.push(
            <span
              className="navigation-menu__item-section-title"
              key={`${index}_grid2`}
            >
              {props.currentCat.menu_2_grid_title}
            </span>
          );
          menuGrid2TitleSet = true;
        }
        if (category.menu_pre_title) {
          menuGrid2.push(
            <span
              className="navigation-menu__item-section-title"
              key={`${index}_grid2_pre`}
            >
              {category.menu_pre_title}
            </span>
          );
        }
        menuGrid2.push(
          <NavigationItem
            appearance="desktop"
            to={{
              pathname: path,
              search: genderParam,
            }}
            onClick={() => {
              props.selectCategory(false);
              return props.onLeaveMenu();
            }}
            key={`${index}_grid2_desc`}
          >
            {category.name}
          </NavigationItem>
        );
      } else if (category.menu_grid_position === "3") {
        if (!menuGrid3TitleSet && props.currentCat.menu_3_grid_title) {
          menuGrid3.push(
            <span
              className="navigation-menu__item-section-title"
              key={`${index}_grid3`}
            >
              {props.currentCat.menu_3_grid_title}
            </span>
          );
          menuGrid3TitleSet = true;
        }
        if (category.menu_pre_title) {
          menuGrid3.push(
            <span
              className="navigation-menu__item-section-title"
              key={`${index}_grid3_title`}
            >
              {category.menu_pre_title}
            </span>
          );
        }
        menuGrid3.push(
          <NavigationItem
            appearance="desktop"
            to={{
              pathname: path,
              search: genderParam,
            }}
            onClick={() => {
              props.selectCategory(false);
              return props.onLeaveMenu();
            }}
            key={`${index}_grid3_desc`}
          >
            {category.name}
          </NavigationItem>
        );
      }
      return null;
    });
  }
  let transitionIn = false;
  if (props.subCats.length) {
    transitionIn = true;
  }
  return (
    <div
      className={classNames("navigation", {
        [`navigation--sticky`]: props.menuExtraClass,
        [`navigation--loading`]: props.loadingGender,
      })}
    >
      <div className="container navigation__wrapper">
        <div className="navigation-menu">
          <Link to="/">
            <Logo />
          </Link>
          <div className="navigation-menu__item-list">
            <GenderNavigation
              shop={props.shop}
              configuration={props.configuration}
              setGender={props.setGender}
              genders={props.genders}
              loadingGender={props.loadingGender}
              currentGender={props.currentGender}
            />
          </div>
          <SearchBar />
          <div className="navigation-menu__icons">
            <AccountLink
              user={props.user}
              loading={props.userLoading}
              logout={props.logout}
            />
            <MiniCart cartLength={props.cartLength} />
          </div>
        </div>
        <div
          className="navigation-menu__item-list__menu"
          onMouseLeave={() => {
            setTimeout(() => {
              props.onLeaveMenu();
            }, 100);
          }}
        >
          {props.categories &&
            props.categories.map((category, index) => {
              if (
                props.config &&
                props.config.storeConfiguration &&
                props.config.storeConfiguration.privatesalesGeneralEnable &&
                parseInt(category.id) ===
                  parseInt(
                    props.config.storeConfiguration.privatesalesGeneralCategory
                  ) &&
                !props.forceDisplayPrivateSalesCategory
              ) {
                return false;
              }
              if (category.hideForCurrentGender) {
                return false;
              }
              if (!currentGender || category.isNoGenderCategory === true) {
                genderParam = null;
              } else {
                genderParam = category.genderParam;
              }
              const path = category.path || "/";
              return (
                <NavigationItem
                  key={path}
                  appearance="desktop"
                  extraClass={category.menu_promote && "promote"}
                  to={{
                    pathname: path,
                    search: genderParam,
                  }}
                  onMouseOver={(e) => {
                    props.setTimeoutOnMouseOver(
                      setTimeout(() => {
                        props.showSubCats(e, index);
                      }, 100)
                    );
                  }}
                  onMouseOut={() => {
                    clearTimeout(props.timeoutOnMouseOver);
                  }}
                  onClick={(e) => {
                    if (category.menu_disable_click) {
                      return e.preventDefault();
                    } else {
                      return props.onLeaveMenu();
                    }
                  }}
                  disableClick={category.menu_disable_click ? true : false}
                  isActive={props.location.pathname === category.path}
                  isPush={props.pushCategorieIds.includes(`${category.id}`)}
                >
                  {category.name}
                </NavigationItem>
              );
            })}
          <Transition in={transitionIn} timeout={500}>
            {(state) => (
              <div
                className="navigation-menu__submenu"
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state],
                }}
              >
                <div className="navigation-menu__submenu__wrapper">
                  <div className="navigation-menu__submenu__wrapper__item">
                    {menuGrid1 && menuGrid1.length > 0 && (
                      <div className="navigation-menu__submenu__sub-category--grid1">
                        {menuGrid1.map((category) => {
                          return category;
                        })}
                      </div>
                    )}
                    {menuGrid2 && menuGrid2.length > 0 && (
                      <div className="navigation-menu__submenu__sub-category--grid2">
                        {menuGrid2}
                      </div>
                    )}
                    {menuGrid3 && menuGrid3.length > 0 && (
                      <div className="navigation-menu__submenu__sub-category--grid3">
                        {menuGrid3}
                      </div>
                    )}
                  </div>
                  <div
                    className="navigation-menu__submenu__wrapper__images"
                    onClick={() => props.onLeaveMenu()}
                  >
                    {props.currentCat.menu_image && (
                      <WysiwygV2
                        content={props.currentCat.menu_image.contentWysiwyg}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </Transition>
        </div>
      </div>
    </div>
  );
};
const MenuNavigation = compose(
  SmartMenuNavigation,
  withState("timeoutOnMouseOver", "setTimeoutOnMouseOver", false),
  withState("menuExtraClass", "setMenuExtraClass", ""),
  withGenderFilter(),
  withHandlers({
    theFunc:
      ({ menuExtraClass, setMenuExtraClass }) =>
      (event) => {
        let offset = window.pageYOffset;
        if (offset > 70 && !menuExtraClass) {
          setMenuExtraClass("sticky-header");
        } else if (offset <= 70 && menuExtraClass) {
          setMenuExtraClass("");
        }
      },
  }),
  lifecycle({
    componentDidMount: function () {
      this.props.theFunc();
      window.addEventListener("scroll", this.props.theFunc, false);
    },
    componentWillUnmount: function () {
      window.removeEventListener("scroll", this.props.theFunc, false);
    },
  })
)(DumbMenuNavigation);

export default MenuNavigation;
