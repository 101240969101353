import React from "react";
import classNames from "classnames";
import { compose } from "recompose";
import { withRouter } from "react-router";

const GenderNavigation = ({
  currentGender,
  setGender,
  loadingGender,
  genders,
  configuration,
  history,
}) => {
  let link = "/";

  return (
    <div className="navigation-menu__item-list__gender-menu">
      {genders.map((gender, index) => {
        return (
          <span
            key={index}
            className={classNames("navigation-menu__item", {
              [`navigation-menu__item--active`]:
                currentGender && currentGender === gender.code,
              [`navigation-menu__item--loading`]: loadingGender === gender.code,
            })}
            onClick={() => {
              if (gender.code === 299) {
                link = `/${configuration?.landingMenUrl}` ?? "/";
              } else if (gender.code === 300) {
                link = `/${configuration?.landingWomenUrl}` ?? "/";
              } else if (gender.code === 301) {
                link = `/${configuration?.landingKidsUrl}` ?? "/";
              }

              setGender(gender.code, null, null, () => {
                history.push({
                  pathname: link,
                });
              });
            }}
          >
            {gender.label}
          </span>
        );
      })}
    </div>
  );
};

export default compose(withRouter)(GenderNavigation);
