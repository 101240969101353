import { compose, withState } from "recompose";
import { graphql } from "react-apollo";
import { trackSearchResult } from "../../../components/helpers/AnalyticsV4/TrackAnlyticsV4";
import { withShop } from "web/core/shop/ShopContext";

const EnhanceSearchBar = ({ SearchQuery }) =>
  compose(
    withState("search", "setSearch", {}),
    withShop(),
    graphql(SearchQuery, {
      skip: ({ search }) => !search.search,
      options: ({ search, finalSearch, ...props }) => ({
        variables: {
          query: search.search,
        },
        onCompleted: () => {
          trackSearchResult(props.shop, search.search);
        },
      }),
      props: ({ data }) => {
        return {
          loading: data.loading,
          error: data.error,
          data,
        };
      },
    })
  );

export default EnhanceSearchBar;
