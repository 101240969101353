import React from "react";
import Link from "theme/components/atoms/Typography/Link";

export default (node, convertNodeToElement) => {
  if (node.name === "a") {
    const target = node.attribs && node.attribs.href;
    const modifiers =
      node.attribs &&
      node.attribs.class &&
      node.attribs.class
        .split(" ")
        .reduce((acc, cur) => ({ ...acc, [cur]: true }), {});

    const isExternal = !target || /^(https?:)?\/\//.test(target);
    const isSpecialLink = !isExternal && /^.+:/.test(target);

    if (!isSpecialLink) {
      return (
        <Link to={target} {...modifiers} external={isExternal}>
          {node.children.map(convertNodeToElement)}
        </Link>
      );
    }
  }
};
