import React from "react";
import PropTypes from "prop-types";

import Wysiwyg from "theme/modules/Wysiwyg";

const toggleMenu = (dropdown, forceOpen = false, forceClose = false) => {
  let element = dropdown;
  if (dropdown.currentTarget) {
    element = dropdown.currentTarget;
  }
  const list = element.querySelector(".link-list__list");
  const icon = element.querySelector(".lnr--default");
  if (list) {
    if (forceOpen) {
      list.style.display = "block";
      return;
    }
    if (list.style.display === "block" || forceClose) {
      if (icon && icon.classList.contains("lnr-chevron-up")) {
        icon.classList.remove("lnr-chevron-up");
        icon.classList.add("lnr-chevron-down");
      }
      list.style.display = "none";
    } else {
      if (icon && icon.classList.contains("lnr-chevron-down")) {
        icon.classList.add("lnr-chevron-up");
        icon.classList.remove("lnr-chevron-down");
      }
      list.style.display = "block";
    }
  }
};
const BottomBar = (props) => {
  React.useEffect(() => {
    const dropdowns = Array.prototype.slice.call(
      document.querySelectorAll(".bottombar .dropdown")
    );

    if (props.isDesktop) {
      dropdowns.forEach((dropdown) => {
        toggleMenu(dropdown, true);
      });
    } else {
      dropdowns.forEach((dropdown) => {
        toggleMenu(dropdown, false, true);
      });

      dropdowns.forEach((dropdown) => {
        dropdown.addEventListener("click", toggleMenu);
      });
    }

    return () =>
      dropdowns.forEach((dropdown) => {
        dropdown.removeEventListener("click", toggleMenu);
      });
  }, [
    props.firstColumn,
    props.secondColumn,
    props.thirdColumn,
    props.fourthColumn,
    props.isDesktop,
  ]);

  return (
    <div className="bottombar">
      <div className="bottombar__wrapper">
        <Wysiwyg content={props.firstColumn} />
        <Wysiwyg content={props.secondColumn} />
        <Wysiwyg content={props.thirdColumn} />
        <Wysiwyg content={props.fourthColumn} />
      </div>
    </div>
  );
};

BottomBar.propTypes = {
  firstColumn: PropTypes.string,
  secondColumn: PropTypes.string,
  thirdColumn: PropTypes.string,
  fourthColumn: PropTypes.string,
};

export default BottomBar;
