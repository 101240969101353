import React from "react";
import { FormattedMessage } from "react-intl";
import ResultsBlock from "theme/components/molecules/ResultsBlock";
import AutocompleteResults, {
  AutocompleteOption,
} from "theme/components/organisms/Autocomplete/AutocompleteResults";
import ProductResultItem from "./ProductResultItem";

const ProductsResults = (props) => {
  let breakAtFirst = false;
  let productToDisplay = null;
  const skuAlreadyDisplayed = [];
  return (
    <ResultsBlock
      title={
        <FormattedMessage
          id="modules.Search.SearchBar.ProductResults.title"
          defaultMessage="Products"
        />
      }
    >
      <AutocompleteResults>
        {props.results.map((result, index) => {
          productToDisplay = result;
          if (
            productToDisplay.visibility === "1" &&
            productToDisplay.parent_product?.sku
          ) {
            productToDisplay = result.parent_product;
          }
          if (
            breakAtFirst ||
            skuAlreadyDisplayed.includes(productToDisplay.sku)
          ) {
            return null;
          }
          if (productToDisplay.sku === props.search?.search) {
            breakAtFirst = true;
          }
          skuAlreadyDisplayed.push(productToDisplay.sku);

          return (
            <AutocompleteOption
              key={result.sku}
              id={`autocomplete-product-${result.sku}`}
              path={productToDisplay.path}
              selected={props.selected}
              onSelect={props.onSelect}
            >
              <ProductResultItem
                result={productToDisplay}
                onChange={props.onChange}
              />
            </AutocompleteOption>
          );
        })}
      </AutocompleteResults>
    </ResultsBlock>
  );
};

export default ProductsResults;
