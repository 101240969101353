import React from "react";
import PropTypes from "prop-types";

import LabelledIcon from "theme/components/molecules/LabelledIcon";

const Phone = ({ size, phone }) => {
  if (!phone) {
    return null;
  }
  return (
    <div className="phone">
      <LabelledIcon icon="phone" size={size}>
        <small>{phone}</small>
      </LabelledIcon>
    </div>
  );
};

Phone.propTypes = {
  phone: PropTypes.string,
  size: PropTypes.string,
};

Phone.defaultProps = {
  size: "mini",
};

export default Phone;
