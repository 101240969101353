import React from "react";
import PropTypes from "prop-types";
import Link from "theme/components/atoms/Typography/Link";

import { FormattedMessage } from "react-intl";
import AccountLink from "theme/modules/User/AccountLink";
import PageError from "theme/modules/PageError";
import MiniCart from "theme/modules/Cart/MiniCart";
import StoreViewSelector from "theme/modules/User/StoreViewSelector";
import Logo from "theme/components/atoms/Logo";
import { LinkButton } from "theme/components/atoms/Button";
import Icon from "theme/components/atoms/Icon";
import Breakpoints from "theme/components/atoms/Breakpoints";
import { withErrorBoundary } from "theme/components/helpers/ErrorBoundary";
import MediaQuery from "theme/components/helpers/MediaQuery";
import Phone from "./Phone";
import SearchBarMobile from "theme/modules/Search/SearchBar/SearchBarMobile";

const MobileTopBar = (props) => {
  return (
    <React.Fragment>
      <div className="topbar__wrapper__left">
        <LinkButton onClick={props.toggleMenu}>
          <Icon
            appearance="vertical"
            icon="burger_menu"
            title="Menu"
            size="mini"
          />
        </LinkButton>
        <div
          className="topbar__wrapper__logo"
          onClick={() => props.setActiveMenu(false)}
        >
          <Link to="/">
            <Logo />
          </Link>
        </div>
      </div>
      <div className="topbar__wrapper__right">
        <div className="topbar__wrapper__menu-links">
          <SearchBarMobile />
        </div>
        <div className="topbar__wrapper__menu-links">
          <AccountLink
            user={props.user}
            loading={props.userLoading}
            logout={props.logout}
          />
        </div>
        <MiniCart cartLength={props.cartLength} />
      </div>
    </React.Fragment>
  );
};

const DesktopTopBar = (props) => (
  <React.Fragment>
    <div className="topbar__wrapper__left">
      <Phone
        size="tiny"
        phone={props.configuration && props.configuration.storePhone}
      />
    </div>
    <div className="topbar__wrapper__right">
      <div className="topbar__wrapper__links">
        <div className="topbar__wrapper__links__wrapper">
          <small className="retailer-link">
            {props.configuration && props.configuration.b2bBaseUrl && (
              <Link to={props.configuration.b2bBaseUrl}>
                <FormattedMessage
                  id="layouts.Header.TopBar.reseller"
                  defaultMessage="Retailer area"
                />
              </Link>
            )}
          </small>
          <small>
            {props.configuration && props.configuration.headerLinkLabel1 && (
              <Link to={props.configuration.headerLinkUrl1.url}>
                {props.configuration.headerLinkLabel1}
              </Link>
            )}
          </small>
          <small>
            {props.configuration && props.configuration.headerLinkLabel2 && (
              <Link to={props.configuration.headerLinkUrl2.url}>
                {props.configuration.headerLinkLabel2}
              </Link>
            )}
          </small>
          <small>
            {props.configuration && props.configuration.headerLinkLabel3 && (
              <Link to={props.configuration.headerLinkUrl3.url}>
                {props.configuration.headerLinkLabel3}
              </Link>
            )}
          </small>
          <small>
            {props.configuration && props.configuration.headerLinkLabel4 && (
              <Link to={props.configuration.headerLinkUrl4.url}>
                {props.configuration.headerLinkLabel4}
              </Link>
            )}
          </small>
        </div>
      </div>
      <StoreViewSelector />
    </div>
  </React.Fragment>
);

const TopBar = (props) => (
  <div className="topbar">
    <MediaQuery minWidth={Breakpoints.menuBreakpoint}>
      {(matches) => (
        <React.Fragment>
          {matches &&
            props.configuration &&
            props.configuration.headerReassurance1 && (
              <div className="delivery">
                <small
                  dangerouslySetInnerHTML={{
                    __html: `${props.configuration.headerReassurance1} ${props.configuration.headerReassurance2} ${props.configuration.headerReassurance3}`,
                  }}
                />
              </div>
            )}
          <div className="container topbar__wrapper">
            {matches ? (
              <DesktopTopBar {...props} />
            ) : (
              <MobileTopBar {...props} />
            )}
          </div>
        </React.Fragment>
      )}
    </MediaQuery>
  </div>
);

TopBar.propTypes = {
  user: PropTypes.object,
};

export default withErrorBoundary(() => <PageError />)(TopBar);
