import { compose, withProps } from "recompose";

import {
  fieldForIdInList,
  fieldForIdInLinkList,
} from "theme/utils/extractFromListHelper";
import { withLinks, withTranslations, withMedias } from "theme/modules/Core";

export default () =>
  compose(
    withTranslations((props) =>
      [
        props.title_1,
        props.title_2,
        props.title_3,
        props.title_4,
        props.subtitle_1,
        props.subtitle_2,
        props.subtitle_3,
        props.subtitle_4,
        props.content_1,
        props.content_2,
        props.content_3,
        props.content_4,
      ].filter(Boolean)
    ),

    withMedias((props) =>
      [props.image_1, props.image_2, props.image_3, props.image_4].filter(
        Boolean
      )
    ),
    withLinks((props) =>
      [props.link_1, props.link_2, props.link_3, props.link_4].filter(Boolean)
    ),
    withProps((props) => {
      const content = [];

      const loading = Boolean(
        props.mediasLoading || props.translationsLoading || props.linksLoading
      );

      if (loading) {
        return {
          loading,
        };
      }
      const extractMedia = fieldForIdInList(props.medias);
      const extractTranslations = fieldForIdInList(props.translations);
      const extractLinks = fieldForIdInLinkList(props.links);

      for (let i = 1; i < 4; i++) {
        if (props["image_" + i]) {
          content[i] = {
            image: extractMedia(props["image_" + i], "url", "/"),
            srcset: extractMedia(props["image_" + i], "srcset", "/"),
            title: extractTranslations(props["title_" + i], "value", ""),
            subtitle: extractTranslations(props["subtitle_" + i], "value", ""),
            content: extractTranslations(props["content_" + i], "value", ""),
            link: extractLinks(props["link_" + i], "url", "/"),
          };
        }
      }
      return {
        loading,
        content,
      };
    })
  );
