import React from "react";
import EnhanceSearchBar from "./EnhanceSearchBar";
import Autocomplete from "theme/components/organisms/Autocomplete/Autocomplete";
import SearchBarQuery from "./SearchBarQuery.gql";
import SearchBarResults from "./SearchBarResults";
import useSearchBar from "./useSearchBar";
import SearchBarForm from "./SearchBarForm";

const SearchBar = ({ search, setSearch, data, loading, error }) => {
  const {
    contentRef,
    searchUrl,
    searchTarget,
    onSubmit,
    onChange,
    onFocus,
    showAutocompleteResults,
    isOpen,
    setIsOpen,
    selected,
    onSelect,
  } = useSearchBar({ search, setSearch, data });

  return (
    <div className="search-box" ref={contentRef}>
      <div className="header__search-overlay"></div>

      <div className="search-box__form">
        <SearchBarForm
          search={search}
          searchTarget={searchTarget}
          data={data}
          selected={selected}
          isExpanded={showAutocompleteResults}
          onSubmit={onSubmit}
          onChange={onChange}
          onFocus={onFocus}
          isOpen={isOpen}
        />
      </div>
      {isOpen && (
        <Autocomplete onClose={() => setIsOpen(false)}>
          <SearchBarResults
            loading={loading ?? true}
            error={error}
            search={search}
            searchUrl={searchUrl}
            results={data?.searchSuggestions}
            selected={selected}
            onSelect={onSelect}
            onChange={onChange}
          />
        </Autocomplete>
      )}
    </div>
  );
};

export default EnhanceSearchBar({
  SearchQuery: SearchBarQuery,
})(SearchBar);
