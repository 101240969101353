import { compose, withState } from "recompose";
import { graphql } from "react-apollo";

const EnhanceSearchBarMobile = ({ SearchQuery }) =>
  compose(
    withState("search", "setSearch", {}),
    graphql(SearchQuery, {
      skip: ({ search }) => !search.search,
      options: ({ search }) => ({
        variables: {
          query: search.search,
        },
      }),
      props: ({ data }) => {
        return {
          loading: data.loading,
          error: data.error,
          data,
        };
      },
    })
  );

export default EnhanceSearchBarMobile;
