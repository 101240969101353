import { compose, withHandlers, withState } from "recompose";
import makeCommandDispatcherWithMessageFeedback from "web/core/apollo/makeCommandDispatcherWithMessageFeedback";
import { trackNewsletterSubmit } from "../../../components/helpers/AnalyticsV4/TrackAnlyticsV4";
import { withShop } from "web/core/shop/ShopContext";

const EnhanceNewsletter = ({
  SubscribeEmailToNewsletterMutation,
  SuccessMessages,
  ErrorMessages,
}) =>
  compose(
    withState("statusMessage", "setStatusMessage", null),
    withShop(),
    withHandlers({
      onSuccess:
        (props) =>
        ({ successMessage }) => {
          props.setStatusMessage({
            hasError: false,
            Message: SuccessMessages[successMessage]
              ? SuccessMessages[successMessage]
              : SuccessMessages["SUBSCRIPTION_DONE"],
          });
        },
      onError:
        (props) =>
        ({ errorMessage }) => {
          props.setStatusMessage({
            hasError: true,
            Message: ErrorMessages[errorMessage]
              ? ErrorMessages[errorMessage]
              : ErrorMessages["UNKOWN"],
          });
        },
    }),
    makeCommandDispatcherWithMessageFeedback(
      {
        subscribeEmailToNewsletter: (props) => (data) => {
          return {
            options: {
              mutation: SubscribeEmailToNewsletterMutation,
              variables: data,
            },
            callback: (result) => {
              if (result.status === "success") {
                trackNewsletterSubmit(props.shop);
              }
            },
          };
        },
      },
      false
    )
  );

export default EnhanceNewsletter;
