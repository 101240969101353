import compose from "recompose/compose";
import { graphql } from "react-apollo";

export default ({ ReassuranceConfigurationQuery }) =>
  compose(
    graphql(ReassuranceConfigurationQuery, {
      props: ({ data }) => {
        return {
          loading: data.loading,
          widgetReassuranceReturnWording:
            data.storeConfiguration &&
            data.storeConfiguration.widgetReassuranceReturnWording,
          widgetReassurancePaymentWording:
            data.storeConfiguration &&
            data.storeConfiguration.widgetReassurancePaymentWording,
          widgetReassuranceShippingWording:
            data.storeConfiguration &&
            data.storeConfiguration.widgetReassuranceShippingWording,
        };
      },
    })
  );
