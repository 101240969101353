import React from "react";
import { stripIndent } from "common-tags";
import ContentSlider from "theme/components/organisms/ContentSlider";
import Reassurance from "theme/components/organisms/Reassurance";
import News from "theme/components/organisms/News";
import OtherLookbook from "theme/components/organisms/OtherLookbook";
import EnhanceWidget from "./EnhanceWidget";

const Widget = ({ type, attributes }) => {
  if (type === "collegien_local/widget_reassurance") {
    return <Reassurance {...attributes} />;
  } else if (type === "collegien_local/widget_contentcarrousel") {
    return <ContentSlider {...attributes} />;
  } else if (type === "collegien_local/widget_otherlookbook") {
    return <OtherLookbook {...attributes} />;
  } else if (type === "collegien_news/widget_news_list") {
    return <News {...attributes} />;
  }

  console.warn(stripIndent`
    Widgets of type ${type} are not supported by your current Widget implementation.
    Please refer to https://developers.front-commerce.com/docs/advanced/theme/wysiwyg.html#Add-a-custom-Widget
  `);
  return null;
};

export default EnhanceWidget()(Widget);
