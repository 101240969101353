import React from "react";
import Subscribe from "theme/modules/Newsletter/Subscribe";
import Icon from "theme/components/atoms/Icon";
import EnhanceSocialNewsletterBar from "./EnhanceSocialNewsletterBar";
import Link from "theme/components/atoms/Typography/Link";

const SocialNewsletterBar = (props) => {
  if (props.loading) {
    return (
      <div className="socialnewsletter-bar">
        <p className="socialnewsletter-bar__description" />
        <Subscribe />
        <div className="socialnewsletter-bar__networks">
          <Icon icon="facebook" size="default" title="Facebook" />
          <Icon icon="instagram" size="default" title="Instagram" />
        </div>
      </div>
    );
  }
  return (
    <div className="socialnewsletter-bar">
      <p
        className="socialnewsletter-bar__description"
        dangerouslySetInnerHTML={{ __html: props.footerNewsletterDescription }}
      />
      <Subscribe />
      <div className="socialnewsletter-bar__networks">
        <Link
          buttonAppearance="ghost"
          to={props.socialFacebookLink ? props.socialFacebookLink : ""}
        >
          <Icon icon="facebook" size="default" title="Facebook" />
        </Link>
        <Link
          buttonAppearance="ghost"
          to={props.socialFacebookLink ? props.socialInstagramLink : ""}
        >
          <Icon icon="instagram" size="default" title="Instagram" />
        </Link>
      </div>
    </div>
  );
};

export default EnhanceSocialNewsletterBar()(SocialNewsletterBar);
