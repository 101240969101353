import React from "react";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";
import { branch, compose } from "recompose";
import StoreViewSelector from "theme/modules/User/StoreViewSelector";
import LabelledIcon from "theme/components/molecules/LabelledIcon";
import Button from "theme/components/atoms/Button";
import Icon from "theme/components/atoms/Icon";
import Link from "theme/components/atoms/Typography/Link";
import Logo from "theme/components/atoms/Logo";
import ListenClickOutside from "theme/components/helpers/ListenClickOutside";
import SmartMenuNavigation from "./SmartMenuNavigation";
import NavigationItem from "./NavigationItem";
import Phone from "../Phone";
import GenderNavigation from "theme/layouts/Header/MenuNavigation/GenderNavigation";
import classNames from "classnames";
import withGenderFilter from "theme/components/helpers/withGenderFilter";

const messages = defineMessages({
  openMenu: {
    id: "layouts.Header.MenuNavigation.Mobile.openMenu",
    defaultMessage: "Open menu",
  },
  back: {
    id: "layouts.Header.MenuNavigation.Mobile.back",
    defaultMessage: "Back",
  },
});

export const DumbMenuNavigation = (props) => {
  const categoriesToShow = props.subCats || props.categories;
  let menuGrid1 = [];
  let menuGrid2 = [];
  let menuGrid3 = [];
  let menuGrid1TitleSet = false;
  let menuGrid2TitleSet = false;
  let menuGrid3TitleSet = false;
  let displayShowAll = true;
  categoriesToShow.forEach((category, idx) => {
    if (category.hideForCurrentGender) {
      return false;
    }
    if (
      props.config &&
      props.config.storeConfiguration &&
      props.config.storeConfiguration.privatesalesGeneralEnable &&
      parseInt(category.id) ===
        parseInt(props.config.storeConfiguration.privatesalesGeneralCategory) &&
      !props.forceDisplayPrivateSalesCategory
    ) {
      return false;
    }
    const path = category.path || "/";
    const hasChildren =
      categoriesToShow[idx].children &&
      categoriesToShow[idx].children.length > 0;

    if (!category.menu_grid_position || category.menu_grid_position === "1") {
      if (!menuGrid1TitleSet && props.currentCat.menu_1_grid_title) {
        menuGrid1TitleSet = true;
        menuGrid1.push(
          <div className="navigation-menu__item--mobile" key={`g1-${idx}`}>
            <span className="navigation-menu__item__section-title">
              {props.currentCat.menu_1_grid_title}
            </span>
          </div>
        );
      }
      if (category.menu_pre_title) {
        menuGrid1.push(
          <div className="navigation-menu__item--mobile" key={`g2-${idx}`}>
            <span className="navigation-menu__item__section-title">
              {category.menu_pre_title}
            </span>
          </div>
        );
      }
      if (props.subCats && displayShowAll) {
        menuGrid1.push(
          <div
            className="navigation-menu__item--mobile navigation-menu__item--mobile--tab-left"
            key={`g3-${idx}`}
          >
            <NavigationItem
              appearance="mobile"
              to={{
                pathname: props.currentCat.path,
                search: props.currentCat.isNoGenderCategory
                  ? null
                  : props.currentCat.genderParam,
              }}
              onClick={(e) => {
                if (props.onLeaveMenu) {
                  props.onLeaveMenu();
                  props.toggleMenu(e);
                } else {
                  props.toggleMenu(e);
                }
              }}
            >
              <LabelledIcon icon="chevron-right" size="tiny" inverted>
                <h4>
                  <FormattedMessage
                    id="layouts.Header.MenuNavigation.seeAll"
                    defaultMessage="Show all"
                  />
                </h4>
              </LabelledIcon>
            </NavigationItem>
          </div>
        );
        displayShowAll = false;
      }
      menuGrid1.push(
        <div
          className="navigation-menu__item--mobile navigation-menu__item--mobile--tab-left"
          key={`g4-${idx}`}
        >
          <NavigationItem
            key={idx}
            appearance="mobile"
            to={{
              pathname: path,
              search: category.isNoGenderCategory ? null : category.genderParam,
            }}
            onClick={(e) => {
              if (hasChildren) {
                props.showSubCats(e, idx);
                e.preventDefault();
                return false;
              } else if (props.onLeaveMenu) {
                props.onLeaveMenu();
                props.toggleMenu(e);
              } else {
                props.toggleMenu(e);
              }
            }}
          >
            <LabelledIcon icon="chevron-right" size="small" inverted>
              <h4>{category.name}</h4>
            </LabelledIcon>
          </NavigationItem>
        </div>
      );
    } else if (category.menu_grid_position === "2") {
      if (!menuGrid2TitleSet && props.currentCat.menu_2_grid_title) {
        menuGrid2TitleSet = true;
        menuGrid2.push(
          <div className="navigation-menu__item--mobile" key={`g5-${idx}`}>
            <span className="navigation-menu__item__section-title">
              {props.currentCat.menu_2_grid_title}
            </span>
          </div>
        );
      }
      if (category.menu_pre_title) {
        menuGrid2.push(
          <div className="navigation-menu__item--mobile" key={`g6-${idx}`}>
            <span className="navigation-menu__item__section-title">
              {category.menu_pre_title}
            </span>
          </div>
        );
      }

      menuGrid2.push(
        <div
          className="navigation-menu__item--mobile navigation-menu__item--mobile--tab-left"
          key={`g7-${idx}`}
        >
          <NavigationItem
            appearance="mobile"
            to={{
              pathname: path,
              search: category.isNoGenderCategory ? null : category.genderParam,
            }}
            onClick={(e) => {
              if (hasChildren) {
                props.showSubCats(e, idx);
                e.preventDefault();
                return false;
              } else if (props.onLeaveMenu) {
                props.onLeaveMenu();
                props.toggleMenu(e);
              } else {
                props.toggleMenu(e);
              }
            }}
          >
            <LabelledIcon icon="chevron-right" size="small" inverted>
              <h4>{category.name}</h4>
            </LabelledIcon>
          </NavigationItem>
        </div>
      );
    } else {
      if (!menuGrid3TitleSet && props.currentCat.menu_3_grid_title) {
        menuGrid3TitleSet = true;
        menuGrid3.push(
          <div className="navigation-menu__item--mobile" key={`g8-${idx}`}>
            <span className="navigation-menu__item__section-title">
              {props.currentCat.menu_3_grid_title}
            </span>
          </div>
        );
      }
      if (category.menu_pre_title) {
        menuGrid3.push(
          <div className="navigation-menu__item--mobile" key={`g9-${idx}`}>
            <span className="navigation-menu__item__section-title">
              {category.menu_pre_title}
            </span>
          </div>
        );
      }
      menuGrid3.push(
        <div
          className="navigation-menu__item--mobile navigation-menu__item--mobile--tab-left"
          key={`g10-${idx}`}
        >
          <NavigationItem
            appearance="mobile"
            to={{
              pathname: path,
              search: category.isNoGenderCategory ? null : category.genderParam,
            }}
            onClick={(e) => {
              if (hasChildren) {
                props.showSubCats(e, idx);
                e.preventDefault();
                return false;
              } else if (props.onLeaveMenu) {
                props.onLeaveMenu();
                props.toggleMenu(e);
              } else {
                props.toggleMenu(e);
              }
            }}
          >
            <LabelledIcon icon="chevron-right" size="small" inverted>
              <h4>{category.name}</h4>
            </LabelledIcon>
          </NavigationItem>
        </div>
      );
    }
    return null;
  });

  const menu = (
    <>
      <GenderNavigation
        shop={props.shop}
        configuration={props.configuration}
        setGender={props.setGender}
        genders={props.genders}
        loadingGender={props.loadingGender}
        currentGender={props.currentGender}
      />
      <div className="navigation">
        <div className="container navigation__wrapper">
          <div className="navigation-menu">
            <>{menuGrid1}</>
            <>{menuGrid2}</>
            <>{menuGrid3}</>
          </div>
        </div>
      </div>
    </>
  );

  const HeaderBottom = (props) => {
    const hasLink =
      (props.configuration && props.configuration.headerLinkLabel1) ||
      props.configuration.headerLinkLabel2 ||
      props.configuration.headerLinkLabel3 ||
      props.configuration.headerLinkLabel4 ||
      props.configuration.b2bBaseUrl;
    return (
      <React.Fragment>
        <div className="menu-popin__bottom">
          <div className="menu-popin__phone">
            <Phone size="small" phone={props.configuration.storePhone} />
          </div>
          <div className="menu-popin__selector">
            <StoreViewSelector storeView={props.user && props.user.storeView} />
          </div>
        </div>
        {hasLink && (
          <div className="menu-popin__links">
            <div className="menu-popin__links__wrapper">
              {props.configuration && props.configuration.b2bBaseUrl && (
                <Link to={props.configuration.b2bBaseUrl}>
                  <FormattedMessage
                    id="layouts.Header.TopBar.reseller"
                    defaultMessage="Retailer area"
                  />
                </Link>
              )}
              {props.configuration && props.configuration.headerLinkLabel1 && (
                <p>
                  <Link to={props.configuration.headerLinkUrl1.url}>
                    {props.configuration.headerLinkLabel1}
                  </Link>
                </p>
              )}
              {props.configuration && props.configuration.headerLinkLabel2 && (
                <p>
                  <Link to={props.configuration.headerLinkUrl2.url}>
                    {props.configuration.headerLinkLabel2}
                  </Link>
                </p>
              )}
              {props.configuration && props.configuration.headerLinkLabel3 && (
                <p>
                  <Link to={props.configuration.headerLinkUrl3.url}>
                    {props.configuration.headerLinkLabel3}
                  </Link>
                </p>
              )}
              {props.configuration && props.configuration.headerLinkLabel4 && (
                <p>
                  <Link to={props.configuration.headerLinkUrl4.url}>
                    {props.configuration.headerLinkLabel4}
                  </Link>
                </p>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  const HeaderTopFirstLevel = () => (
    <div className="menu-popin__icons">
      <Link to="/" onClick={props.toggleMenu}>
        <Logo />
      </Link>

      <Link
        to="/"
        onClick={(e) => {
          props.toggleMenu(e);
          props.onLeaveMenu(e);
        }}
      >
        <Icon
          icon="cross"
          size="small"
          title={props.intl.formatMessage(messages.back)}
        />
      </Link>
    </div>
  );

  const HeaderTopSecondLevel = (props) => (
    <div className="menu-popin__icons">
      <Button appearance="link" onClick={props.onLeaveMenu}>
        <LabelledIcon
          title={props.intl.formatMessage(messages.back)}
          icon="chevron-left"
          size="small"
        ></LabelledIcon>
      </Button>

      <p className="menu-popin__title">
        {`${props.currentCat.name} ${props.currentGenderLabel ?? ""}`}
      </p>

      <Link
        to="/"
        onClick={(e) => {
          props.toggleMenu(e);
          props.onLeaveMenu(e);
        }}
      >
        <Icon
          icon="cross"
          size="small"
          title={props.intl.formatMessage(messages.back)}
        />
      </Link>
    </div>
  );

  return (
    <ListenClickOutside
      onClickOutside={() => {
        props.onLeaveMenu();
        props.hideMenu();
      }}
    >
      {({ setRef }) => (
        <div
          className={classNames("menu-popin", {
            "menu-popin--subcat": props.subCats,
            [`menu-popin--loading`]: props.loadingGender,
          })}
          id="mobile-navigation-menu"
          ref={setRef}
        >
          {props.subCats ? (
            <HeaderTopSecondLevel {...props} />
          ) : (
            <HeaderTopFirstLevel {...props} />
          )}
          <div className="menu-popin__navigation">{menu}</div>

          {props.subCats ? null : <HeaderBottom {...props} />}
        </div>
      )}
    </ListenClickOutside>
  );
};

const MenuNavigation = compose(
  injectIntl,
  SmartMenuNavigation,
  branch(
    (props) => props.activeMenu,
    (BaseComponent) => BaseComponent,
    () => () => null
  ),
  withGenderFilter()
)(DumbMenuNavigation);

export default MenuNavigation;
