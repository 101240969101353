import React, { Fragment } from "react";
import { compose } from "recompose";
import { injectIntl, defineMessages } from "react-intl";
import EnhanceRemoveItemCart from "../CartItemRemoveForm/EnhanceRemoveItemCart";
import RemoveCartItemMutation from "theme/modules/Cart/CartItem/CartItemRemoveForm/RemoveCartItemMutation.gql";
import Price from "theme/components/atoms/Typography/Price";
import TrackingLink from "theme/components/atoms/Typography/Link/TrackingLink";
import { InlineAlert } from "theme/components/molecules/Alert";
import Spinner from "theme/components/atoms/Spinner";
import ResizedImage from "theme/components/atoms/ResizedImage";

const messages = defineMessages({
  removeItem: {
    id: "modules.Cart.MiniCart.MiniCartContent.MiniCartItem.removeItem",
    defaultMessage: "Remove",
  },
  optionLabel: {
    id: "modules.Cart.MiniCart.MiniCartContent.MiniCartItem.optionLabel",
    defaultMessage: "{optionName}: {optionValue}",
  },
  qty: {
    id: "modules.AddToCart.qty",
    defaultMessage: "Quantity",
  },
});

const MiniCartItem = ({ item, intl, removeItemStatus, removeItem }) => {
  return (
    <div className="mini-cart-item">
      {removeItemStatus.commandPending ? (
        <Spinner />
      ) : (
        <Fragment>
          {removeItemStatus && removeItemStatus.hasMessage && (
            <InlineAlert
              withIcon
              appearance={removeItemStatus.success ? "success" : "error"}
            >
              {removeItemStatus.message}
            </InlineAlert>
          )}
          <div className="mini-cart-item__image">
            {item.product.parent_product
              ? item.product.parent_product.imageUrl && (
                  <ResizedImage
                    src={item.product.parent_product.imageUrl}
                    alt={item.name}
                    format="small"
                    appearance="full"
                  />
                )
              : item.product.imageUrl && (
                  <ResizedImage
                    src={item.product.imageUrl}
                    alt={item.name}
                    format="small"
                    appearance="full"
                  />
                )}
          </div>
          <div className="mini-cart-item__details">
            <div>
              <TrackingLink
                to={
                  item.product.parent_product
                    ? item.product.parent_product.path
                    : item.product.path
                }
                eventName="Product Clicked"
                eventPayload={{
                  sku: item.sku,
                  name: item.name,
                }}
              >
                {item.name}
              </TrackingLink>
            </div>
            <div>
              {item.options &&
                item.options.length > 0 &&
                item.options.map((option, key) => (
                  <span key={key} className="mini-cart-item__details__option">
                    {option.value}
                  </span>
                ))}
              <span className="mini-cart-item__details__qty">
                {intl.formatMessage(messages.qty)}
                {": "}
                {item.qty}
              </span>
              <Price price={item.priceInfo.rowTotalInclTax} />
            </div>
            <div>
              <span
                onClick={removeItem}
                className="remove"
                state={removeItemStatus.commandPending ? "pending" : undefined}
              >
                {intl.formatMessage(messages.removeItem)}
              </span>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default compose(
  EnhanceRemoveItemCart({ RemoveCartItemMutation }),
  injectIntl
)(MiniCartItem);
