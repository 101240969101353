import { compose, withState, withHandlers, pure, lifecycle } from "recompose";
import { withRouter } from "react-router";
import { withCookies } from "react-cookie";
import EnhanceMenuNavigation from "./EnhanceMenuNavigation";
import MenuNavigationQuery from "./MenuNavigationQuery.gql";
import { graphql } from "react-apollo";
import ConfigurationQuery from "./ConfigurationQuery.gql";

const SmartMenuNavigation = (BaseComponent) => {
  return compose(
    withRouter,
    EnhanceMenuNavigation({
      MenuNavigationQuery,
    }),
    withState("subCats", "setSubCats", false),
    withState(
      "forceDisplayPrivateSalesCategory",
      "setForceDisplayPrivateSalesCategory",
      false
    ),
    withState("currentCat", "selectCategory", {
      id: null,
      name: null,
      path: null,
      menu_image: null,
      genderParam: null,
      menu_1_grid_title: null,
      menu_2_grid_title: null,
      menu_3_grid_title: null,
    }),
    graphql(ConfigurationQuery, {
      name: "config",
    }),
    withHandlers({
      showSubCats: (props) => (e, index) => {
        props.selectCategory({
          id: index,
          name: props.categories[index].name,
          path: props.categories[index].path,
          menu_image: props.categories[index].menu_image,
          genderParam: props.categories[index].genderParam,
          menu_1_grid_title: props.categories[index].menu_1_grid_title,
          menu_2_grid_title: props.categories[index].menu_2_grid_title,
          menu_3_grid_title: props.categories[index].menu_3_grid_title,
        });
        props.setSubCats(props.categories[index].children);
      },
      onLeaveMenu: (props) => () => {
        props.selectCategory({
          id: null,
          name: null,
          path: null,
          menu_image: null,
          genderParam: null,
          menu_1_grid_title: null,
          menu_2_grid_title: null,
          menu_3_grid_title: null,
        });
        props.setSubCats(false);
      },
    }),
    pure,
    withCookies,
    lifecycle({
      componentDidMount: function () {
        if (this.props.cookies.get("access_private_sales")) {
          this.props.setForceDisplayPrivateSalesCategory(true);
        }
      },
    })
  )(BaseComponent);
};

export default SmartMenuNavigation;
