import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ReactModal from "react-modal";
import CloseModal from "./CloseModal";

class Modal extends Component {
  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        onAfterOpen={this.props.onAfterOpen}
        className={{
          base: classnames("modal", {
            [`modal--${this.props.appearance}`]: this.props.appearance,
            [`${this.props.extraClass}`]: this.props.extraClass,
          }),
          afterOpen: "modal--opened",
          beforeClose: "modal--closed",
        }}
        overlayClassName={{
          base: "modal__overlay",
          afterOpen: "modal__overlay--opened",
          beforeClose: "modal__overlay--closed",
        }}
        closeTimeoutMS={300}
      >
        <div>
          <div className="modal__content">
            {!this.props.dangerouslyIgnoreCloseButton && (
              <CloseModal onClick={this.props.onRequestClose} />
            )}
            <div className="modal__content__main">{this.props.children}</div>
            {this.props.actions && (
              <div className="modal__content__actions">
                {this.props.actions}
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
  dangerouslyIgnoreCloseButton: PropTypes.bool,
  appearance: PropTypes.oneOf([
    "default",
    "full",
    "full-no-spacing",
    "central",
  ]),
};

if (typeof window !== "undefined") {
  ReactModal.setAppElement("body");
}

export default Modal;
