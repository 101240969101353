import React from "react";
import Link from "theme/components/atoms/Typography/Link";
import AutocompleteItem from "theme/components/organisms/Autocomplete/AutocompleteItem/AutocompleteItem";

const CategoriesResultItem = ({ result, onChange }) => {
  let categoryPathName = "";
  let nameReformat = "";

  if (result.path) {
    nameReformat = result.path
      .split("/")
      .filter((partie) => partie.trim() !== "")
      .join(" > ")
      .replace(".html", "")
      .replace(/[-\s]\S/g, (match) => " " + match.charAt(1).toUpperCase())
      .replace(/(^|\s)\S/g, (l) => l.toUpperCase());
  }

  return (
    <Link
      to={result.path}
      onClick={() => {
        onChange({ search: "" });
      }}
      clickType="block"
    >
      <AutocompleteItem
        name={nameReformat}
        addon={
          categoryPathName && (
            <span className="category-result__breadcrumb">
              {categoryPathName}
            </span>
          )
        }
      />
    </Link>
  );
};

export default CategoriesResultItem;
